import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="Политика конфиденциальности " description="Политика конфиденциальности - развивайте свой бизнес, используя автоматизацию многоканального маркетинга, основанную на данных, и побеждайте конкурентов!" />
        <Hero title="Политика конфиденциальности" button="false" classes="heroes--full has-text-centered" />

        <div className="container container--pad pad-t-2 pad-b-6 mar-t-6 mar-b-6">
          <div className="columns">
            <div className="column" />
            <div className="column is-two-thirds">
              <div className="columns is-centered">
                <div className="post__content content column">
                  <p><strong>В системе ExpertSender личная информация пользователей и посетителей вашегосайта будет надежно защищена. Защита персональных данных поддерживает деловую репутацию вашей компании и стимулирует развитие прочных партнерских отношений. Для получения дополнительной информациио реализации кампаний емейл-маркетинга, основанных на подтверждении рассылки, смотрите раздел Политика Анти-Спам, а также связывайтесь с нами в любое удобное время.</strong></p>
                  <h4>Сбор информации</h4>
                  <p>Мы собираем только следующую информацию о пользователях: название домена и IP-адрес каждого посетителя нашего сайта, адреса электронной почты тех, кто отправил нам сообщение, статистическую информацию о просмотренных страницах, а также данные, самостоятельно предоставленные пользователями. Для изучения потребностей пользователей относительно дизайна сайта мы определяем типы браузера, которыми они пользуются. На сайте есть дополнительный раздел, в котором вам нужно будет оставить свое имя, номер телефона, название компании и адрес электронной почты.Информация наших пользователей никогда не будет продана или передана в пользование другим компаниям. Но мы оставляем за собой право раскрыть ваши персональные данные в случаях, предусмотренных существующим законодательством, а также когда это необходимо для защиты прав компании при проведении судебных разбирательств и исполнении судебных постановлений или решений.</p>
                  <h4>Ваши листы рассылки и база данных</h4>
                  <p>Листы рассылки вашей компании хранятся на защищенном сервере. ExpertSender гарантирует, что не будет продавать их или предоставлять доступ к данной информации. Листы рассылки используются исключительно в целях оказания поддержки пользователям и предотвращения неправильного использования информации. Мы гарантируем строгую конфиденциальность ваших данных.</p>
                  <h4>Пиксели</h4>
                  <p>В большинство отправляемых посредством платформы ExpertSender HTML-сообщений включены пиксели. Пиксель – это маленькое изображение в тексте емейла, которое активируется, когда HTML-письмо открывается через запрос об изображении на наших серверах.Таким образом, пользователи системы могут отследить совокупное число открытых электронных писем. Пиксель не собирает личные данные. Пользователи, которые не хотят получать электронные письма с пикселями, могут сделать запрос о получении сообщений в текстовом формате.Мы также обеспечиваем функцию отслеживания времени, когда получатель кликнул на ссылку в сообщении, отправленном через нашу систему. Данная функция платформы используется для сбора данных о кликнутых ссылках, количестве кликов, а также для определения совокупного количества кликнутых ссылок. С помощью данной опции нельзя собрать личные данные.</p>
                  <h4>Файлы Cookies</h4>
                  <p>Мы используем файлы cookie для записи информации о сеансе пользователя, например, об его прошлой активности. Это нужно для обеспечения лучшего сервиса по возвращении пользователя на сайт или кастомизации содержания страницы в зависимости оттипа браузера посетителя.</p>
                  <h4>Технология безопасности</h4>
                  <p>ExpertSender работает в защищенной среде с использованием последних технологий. Все данные находятся в контролируемом, безопасном центре хранения и обработки данных, который предотвращает несанкционированный доступ и гарантирует защиту информации. Информация о пользователях и их интересах не доступна сторонним лицам.</p>
                  <h4>Уведомление об изменениях</h4>
                  <p>В случае изменений Политики конфиденциальности все пользователи системы ExpertSender будут уведомлены об этом по электронной почте. Если мы решим использовать личные данные в формате, отличающемся от первоначальных договоренностей, мы сообщим вам об этом факте. В любом случае, у вас есть право не согласиться с новыми условиями. Все изменения Политики конфиденциальности будут отображены в данном разделе, либо на нашей домашней странице, либо в любом другом месте, где вы сможете ознакомиться с поправками. Мы оставляем за собой право изменять Политику конфиденциальности в любое время, поэтому вам нужно периодически проверять наличие обновлений. При внесении изменений вы будете уведомлены об этом в данном разделе, посредством электронной почты или через наш сайт.</p>
                </div>
              </div>
            </div>
            <div className="column" />
          </div>
        </div>
      </Layout>
    )
  }
}

export default SuccessStories
